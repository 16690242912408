html{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
#root{
    margin: 0;
    padding: 0;
}

body{
    margin: 0;
    background-color: white;
    position: absolute;
}

.App {
    font-family: "Lucida Grande";
    width: 100vw;
}

.banner{
    background-image: linear-gradient(rgb(255, 72, 68), #de7f09);
    text-align: center;
    width: 100%;
    height: 100vh;
    color: whitesmoke;

}

.bannerHeader{
    font-size: 3em;
    margin-top: 0;
    padding-top: 25vh;
    box-sizing: border-box;
    font-family: 'Raleway', Helvetica, Arial, sans-serif;
    font-size: 59px;
    line-height: 70px;
}

.bannerInfo{
    font-size: 1em;
    z-index: 10;
    font-family: 'Goudy Bookletter 1911', Georgia, Times, serif;
    font-size: 20px;

}
.image{
    display: inline-block;
    border: 1px solid;
    width: 200px;
    height: 200px;
}
.sayHi{
    margin-top: 8vh;
    background-color: white;
    position: absolute;
    width: 10vw;
    height: 7vh;
    right: 8%;
    color: #de7f09;
    border: #de7f09 2px solid;
    border-radius: 20px;
    font-size: 1.3em;
    outline: none;
}

.name{
    color: whitesmoke;
    position: absolute;
    left: 2%;
    width: 10%;
    text-align: left;
    opacity: .7;
}

.avatar {
    width: 30vh;
}
.infoHeader{
    width: 100%;
    height: 50vh;
    background-color: #de7f09;
    text-align: center;
    margin-bottom: -10vh;
}

.intro{
    display: block;
    margin: 0 auto;
    width: 60%;
    height: 50%;
    padding-top: 8vh;
}
.introFooter{
    display: block;
    margin: 0 auto;
    width: 60%;
    height: 50%;
    padding-top: 8vh;
}

.introduction{
    color: whitesmoke;
    font-family: "Lucida Grande";
    line-height: 1.5em;
}
.introHeader{

}
.introInfo{
    font-size: 1em;
}
.infoExtender{
    width: 50%;
}

.infoContainer{
    overflow: hidden;
    border: 1px solid gray;
    text-align: center;
    width: 60%;
    display: block;
    margin: 0 auto;
    border-radius: 30px;
    background-color: white;
    box-shadow: 1px 1px 1px #a2a2a2;
}

.infoTable{
    margin-top: 5%;
    display: inline-block;
}

.info{
    text-align: center;
    background-color: white;
    width: 49%;
    color: #666666;
    display: inline-block;
    overflow: hidden;
    padding-bottom: 10%;
    height: 50vh;
}
.infoL{
    border-right: 1px solid #dddddd;
}
.infoR{
    border-left: 1px solid #dddddd;
}
.infoIcon{
    height: 60px;
    display: block;
    margin: 0 auto;
    margin-top: 15%;
    fill: #de7f09;
}
.infoSecond{
    color: #de7f09;
    font-weight: bold;
    padding: 0;
    margin: 0;

}

.infoList{
    list-style: none;
    padding: 0;
}

.infoListItems{

}


.footer{
  width: 100%;
  height: 50vh;
    background-color: #222222;
}

.projectHeaderStart{
    text-align: center;
    margin-top: 20vh;
    color: #666666;
}

.imageContainer{
    width: 100%;
    overflow: hidden;
}


.box{
    border: 1px solid black;
    width: 100%;
    height: 300px;
    background-color: blueviolet;
}

.seeAll{
    background-color: #e7d8db;
    width: 15vw;
    height: 7vh;
    color: #de7f09;
    font-size: 1.5em;
    margin-top: 5vh;
    border: #de7f09 1px solid;
    border-radius: 30px;
    outline: none;
}

.companies{
    margin-bottom: 15%;
    color: #666666;
    margin-top: 15%;
    text-align: center;
    display: block;
}

.companyImage{
    height: 100%;
}

.companyIcon {
    display: inline-block;
    height: 75px;
    margin: 2%;
}

.companyIcon:hover{
    -webkit-transform: scale(1.2);
}

.secondInfoContainer{
    margin-top: 15%;
}


.footer{
    color: whitesmoke;
    margin-top: 10%;
    background-color: #222222;
    text-align: center;
}

.filterProjects{
    width: 80%;
    display: block;
    margin: 0 auto;
}


.filterButton{
    height: 36px;
    border-radius: 15px;
    margin: 0.4%;
    background-color: #e7e7e7;
    text-decoration: none;
    -moz-box-shadow: 1px 1px 1px rgba(0,0,0,.5);
    -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,.5);
    box-shadow: 1px 2px 3px rgba(0,0,.5,.5);
    font-size: 1.1em;
    outline: none;
}
.filterButton:hover{
    transform: scale(1.2);
}

.projectPage{
    overflow: hidden;
}

.pintrestView{
    margin-top: 1%;
    margin-right: 10%;
    margin-left: 10%;
}
.projectContainer{
    border: 0px solid;
    border-radius: 5px;
    background-color: #222;
}

.imageContainer{
    overflow: hidden;
    width: 98%;
    margin: 0 auto;
    padding-top: 1%;
}
.projectImage{
    height: 100%;
    display: block;
    margin: 0 50%;
    vertical-align: middle;
    transform: translate(-50%);
}
.projectHeaderContainer{
    border-radius: 5px;
    position: relative;
    width: 100%;
    height: 50px;
    padding-top: 10px;
    background-color: rgb(57, 52, 61);
}
.projectHeader{
    text-align: center;
    width: 100%;
    color: #cac6c6;
}
.projectLink{
    text-decoration: none;
    color: #222;
}
.projectInformation{
    margin-top: 20%;
    margin-left: 5%;
    margin-right: 5%;
    position: absolute;
    top: 0;
    color: #CCCCCC;
}
.projectLine{
    position: absolute;
    left: 10%;
    top: 45px;
    width: 80%;
}

.projectButtons{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-decoration: none;

}

.projectButton{
    height: 36px;
    width: 80%;
    border-radius: 15px;
    margin: 15px 10%;
    background-color: #AAA;
    text-decoration: none;
    -moz-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
    -webkit-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
    box-shadow: 3px 6px 9px rgba(0,0,.5,.5);
    font-size: 1.1em;
}
.githubButton{
    width: 30px;
    height: 30px;
    position: absolute;
    margin: 18px 40px;
}
.projectButton:hover{
    background-color: #777;
    border: 1px solid #222;
    -moz-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
    -webkit-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
    box-shadow: 3px 6px 9px rgba(0,0,.5,.5);
}


.projectPage{
    position: absolute;
    width: 100%;
    height: 100%;
}

.content{
    position: absolute;
    background-color: white;
    width: 60%;
    overflow-y: scroll;
    margin-left: 30%;
    margin-top: 5%;
    -moz-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
    -webkit-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
    box-shadow: 3px 6px 9px rgba(0,0,.5,.5);
}

.menuContainer{
    position: absolute;
    height: 100%;
    width: 20%;
}

.menuHeader{
    font-size: 2.5em;
    position: absolute;
    width: 100%;
    height: 10%;
    color: #CCC;
    margin-left: 3vw;
    margin-top: 7vh;
    text-shadow: 2px 2px #353839;
}

.menuOptions{
    position: absolute;
    margin-top: 20vh;
    width: 100%;
    height: 60%;
}

.menuFilter{
    position: absolute;
    height: 5%;
    color: #CCC;
    text-decoration: underline #CCC;
    margin-top: 16vh;
    margin-left: 2vw;

}

.rowContainer{
    position: relative;
    height: 7vh;
}
.filterLine{
    position: absolute;
    margin-top: 19vh;
    width: 80%;
    stroke: #7e0010;
}
.row{
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transition: transform 1.05s ease-in-out;
    -moz-transition:transform 1.05s ease-in-out;
    -ms-transition:transform 1.05s ease-in-out;
}
.rowName{
    position: absolute;
    font-size: 1.2em;
    margin-left: 20%;
}
.languageIcon{
    position: absolute;
    height: 3vh;
    margin-left: 8%;
    margin-top: 2vh;
}


/* =========================== */
.cloudscontainer{
    position: absolute;
    height: 100vh;
    width: 100vw;
}

.iconButtons{
    display: inline-block;
    width: 40vw;
    height: 20vh;
}
.iconButton{
    display: inline-block;
    width: 10%;
    margin: 2%;
}
.iconButton:hover{
    transform: scale(1.2);
}
.mountain{
    fill: #af651b;
    bottom: 0;
    width: 80%;
    display: block;
    margin: 0 auto;
}

.formMail{
    display: block;
    margin: 0 auto;
    width:100%;
    height: 100%;
    position: relative;
    padding: 5% 25%;
    box-sizing: border-box;
}
.messageMail{
    position: absolute;
    bottom: 10%;
    left: 10%;
    width: 80%;
    height: 50%;
}

.infoMail{
    width: 100%;
    top: 0;
    left: 0;
}
.buttonMail{
    position: absolute;
    right: 2%;
    top: 2%;
    border: none;
    font-size: 1em;
}

.aboutMe{
    width: 100%;
    height: 90vh;
    background-color: #fa9026;
    color: #222222;
    margin-top: 30vh;
}

.imageOfMe{
    margin-top: 30vh;
    margin-left: -17vh;
    width: 30vh;
    height: 30vh;
    overflow: hidden;
    border-radius: 50%;
    float: left;
    border: 15px solid #222;
    background-color: #222;
}
.image{
    height: auto;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.aboutBackground{
    background-color: #222;
    float: left;
    height: 100%;
    width: 30vw;
}
.aboutContainer{
    margin-left: 5vh;
    color: whitesmoke;
    width: 50vw;
    float: left;
    height: 100%;
}
.aboutHeader{
    display: inline-block;

}
.aboutInfo{
    display: inline-block;
    font-size: 1.2em;
}

.test{
    width: 50vw;
    margin-top: 15vw;
}

.bye{
    height: 30vh;
    width: 100vw;
    color: #222222;
}

.byeInfo{
    margin-top: 30vh;
    text-align: center;
}


/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .bye{
        height: 30vh;
        color: #222222;
        display: inline-block;
        margin: 0 auto;
    }

    .byeInfo{
        margin-top: 0;
        text-align: center;
    }
    .name{
        width: 80%;
        font-size: 1.2em;
        opacity: .7;
    }

    .sayHi{
        position: absolute;
        width: 30vw;
        height: 7vh;
        right: 8%;
        margin-top: 1vh;
        color: #de7f09;
        border: #de7f09 2px solid;
        border-radius: 20px;
        font-size: 1.3em;
    }
    .avatar{
        width: 40vh;

    }
    .intro{
        position: absolute;
        margin-left: 10%;
        padding-top: 10vh;
        width: 80%;
        height: 50vh;
        overflow: hidden;
    }
    .info{
        display: block;
        position: relative;
        width: 100%;
        border-bottom: #c3c3c3 1px solid;
        height: 50%;
    }
    .infoContainer{
        width: 80%;
        display: block;
        margin: 0 auto;
        margin-top: -20vh;
        border-radius: 30px;
        background-color: white;
        box-shadow: 1px 1px 1px #a2a2a2;
    }
    .infoHeader{
        height: 80vh;
    }

    .companies{
        margin-top: 30%;
    }
    .bannerHeader{
        font-size: 3em;
        padding-top: 8vh;
        font-family: "Lucida Grande";
        font-weight: bolder;

    }

    .bannerInfo{
        font-size: 1em;
        width: 80%;
        margin-left: 10%;
    }

    .introHeader{
        font-size: 1.5em;
    }
    .introInfo{

        font-size: 0.9em;
    }
    .seeAll{
        position: relative;
        width: 40%;
    }


    .aboutMe{
        width: 100%;
        height: 140vh;
        background-color: #fa9026;
        color: #222222;
        margin-top: 30vh;
    }

    .imageOfMe{
        margin: -17vh auto 0;
        width: 30vh;
        height: 30vh;
        overflow: hidden;
        border-radius: 50%;
        float: none;
        border: 15px solid #222;
        background-color: #222;
    }
    .image{
        height: auto;
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .aboutBackground{
        background-color: #222;
        margin-bottom: -17vh;
        height: 30vh;
        width: 100%;
    }
    .aboutContainer{
        margin-left: 5vh;
        color: whitesmoke;
        width: 50vw;
        float: left;
        height: 100%;
    }
    .aboutHeader{
        display: inline-block;

    }
    .aboutInfo{
        display: inline-block;
        font-size: 1em;
    }

    .test{
        width: 80vw;
        margin: 0 auto;
        text-align: center;
        margin-top: 15vw;
    }

    .iconButtons{
        display: inline-block;
        margin-left: 0;
        width: 100vw;
        height: 20vh;
    }
    .iconButton{
        width: 20%;
        margin: 2%;
    }
    .iconButton:hover{
        transform: scale(1.2);
    }
    .introFooter{
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding-top: 0;
    }
    .filterButton{
        margin: 1%;
    }
}















